<script>
// Extensions
import View from '@/views/View'

// Mixins
import LoadSections from '@/mixins/load-sections'

export default {
   name: 'Subjects',

   metaInfo: { title: 'Subjects' },

   extends: View,

   mixins: [
      LoadSections([
         'page-subjects',
      ]),
   ],

   props: {
      id: {
         type: String,
         default: 'subjects',
      },
   },
}
</script>
